import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/components/LayoutPost.js";
import Video from "../components/Video";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div {...{
      "className": "fixed-width"
    }}><h1 parentName="div">{`Experience Tube`}</h1><hr parentName="div"></hr><div parentName="div" {...{
        "className": "auto-grid"
      }}><div parentName="div"><p parentName="div">{`Experience Tube was the first site I worked on for Meow Wolf. I really enjoyed using GSAP / GreenSock to build out the mesmerizing animations for the WordPress theme we made here.`}</p><p parentName="div"><a parentName="p" {...{
              "href": "https://experiencetube.com"
            }}>{`Check out https://experiencetube.com`}</a></p></div><div parentName="div"><blockquote parentName="div">
            <p parentName="blockquote">{`Codebase: React (Next.js), Apollo Graphql, SCSS`}</p>
          </blockquote></div></div><div parentName="div" {...{
        "className": "auto-grid"
      }}><Video type="youtube" url="WSKV-LyBU8Q" mdxType="Video" /><Video type="youtube" url="4z2g6ikK0B8" mdxType="Video" /></div></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      